<template>
  <base-list :items="items" />
</template>

<script>
import {
  genSubheaderItem,
  genDefaultItem,
  genMultilineItem,
  hideIfEmpty,
  genServiceNameItem
} from '@/utils/list-generators';
import { computed, inject } from '@vue/composition-api';

import { usePositionItemMenu } from './positionItemMenu';
import { useCoordinatesItemMenu } from './coordinatesItemMenu';
import { useCopy } from '@/compositions/copy';
import { formatCoordinates } from '@/utils';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { useGeotagItemMenu } from '@/modules/geotags/compositions/geotagItemMenu';
import { genAccessListFragment } from '@/modules/access/compositions/access-list-fragment';

export default {
  name: 'PlanCardGeneral',
  setup() {
    const entity = inject('entity');
    const { copyWithAlert } = useCopy();

    const { genMenu: genPositionItemMenu } = usePositionItemMenu();
    const { genMenu: genGeotagItemMenu } = useGeotagItemMenu();
    const { genMenu: genCoordinatesItemMenu } = useCoordinatesItemMenu();
    const { getGeotagById } = useGeotags();

    const items = computed(() => [
      genSubheaderItem('Position'),
      genDefaultItem({
        title: 'Center',
        subTitle: formatCoordinates(entity.value.positionCenter.value),
        icon: '$marker_set',
        invert: true,
        actions: genCoordinatesItemMenu(entity.value.positionCenter)
      }),
      genDefaultItem({
        title: 'Geotag',
        subTitle: getGeotagById(entity.value.positionGeotagId.value)?.name,
        icon: '$geotag',
        invert: true,
        actions: genGeotagItemMenu(
          entity.value.positionGeotagId,
          entity.value.id
        )
      }),
      genDefaultItem({
        title: 'Width (m)',
        subTitle: entity.value.positionWidth.value,
        icon: '$size',
        invert: true,
        actions: genPositionItemMenu(entity.value.positionWidth)
      }),
      genDefaultItem({
        title: 'Height (m)',
        subTitle: entity.value.positionHeight.value,
        icon: '$size',
        invert: true,
        actions: genPositionItemMenu(entity.value.positionHeight)
      }),
      genDefaultItem({
        title: 'Rotation (deg)',
        subTitle: entity.value.positionRotation.value,
        icon: '$rotation',
        invert: true,
        actions: genPositionItemMenu(entity.value.positionRotation)
      }),
      ...hideIfEmpty(entity.value.description, [
        genSubheaderItem('Description'),
        genMultilineItem({
          text: entity.value.description
        })
      ]),
      ...genAccessListFragment({
        readerGroup: entity.value.userGroupByReadergroup.groupName,
        userGroup: entity.value.userGroupByUsergroup.groupName,
        editorGroup: entity.value.userGroupByEditorgroup.groupName
      }),
      genSubheaderItem('Service'),
      genDefaultItem(
        {
          icon: '$uuid',
          title: entity.value.id
        },
        {
          click: () => copyWithAlert(entity.value.id)
        }
      ),
      genServiceNameItem(entity.value.name, {
        click: () => copyWithAlert(entity.value.name)
      })
    ]);

    return { items };
  }
};
</script>

<style></style>
